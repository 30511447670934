<template>
  <div class="playbook-list-item" @click.prevent="$emit('selected')">
    <div class="left">
    </div>
    <div class="right">
      <div class="name">
        {{ playbook.displayName }} 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['playbook']
};
</script>

<style scoped lang="scss">
.playbook-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    // min-width: 45px;
    text-align: center;
  }

  .right {
    padding-left: 15px;
    flex-grow: 1;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  .name {
    font-weight: 500;
  }
}
</style>